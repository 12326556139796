.carousel {
  margin: 0 auto;
  max-width: 500px;
  min-height: 500px;
  margin-top: 2em;
}

.gallery-cell {
  max-width: 500px;
  width: 100%;
  padding: 0 2px;
}
.gallery-cell:hover {
  cursor: pointer;
}

.gallery-cell img {
  max-width: 100%;
  border: 5px solid #262626;
}

.flickity-button {
  background: transparent;
}
.flickity-button {
  background: transparent;
}

.flickity-button:hover {
  background: transparent;
}

.flickity-button-icon {
  fill: black;
}

.flickity-prev-next-button {
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: none;
}

/* position outside */
.flickity-prev-next-button.previous {
  left: -30px;
}
.flickity-prev-next-button.next {
  right: -30px;
}

@media screen and (min-width: 765px) {
  .flickity-prev-next-button {
    display: block;
  }
}

@media screen and (min-height: 500px) {
  .carousel {
    margin: 0 auto;
    max-width: 350px;
    min-height: 300px;
    margin-top: 2em;
  }
}
@media screen and (min-height: 800px) {
  .carousel {
    margin: 0 auto;
    max-width: 500px;
    /* min-height: 500px; */
    margin-top: 2em;
  }
}
