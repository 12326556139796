.navigation {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.nav-container a {
  margin: 0 0.5em;
}

.nav-btn {
  border: none;
  border: 1px solid #262626;
  cursor: pointer;
  font-family: inherit;
  font-size: 0.8em;
  background-color: transparent;
  color: #262626;
}

.active .nav-btn {
  color: #f7f8fa;
  background-color: #262626;
}

.small-text {
  display: block;
  margin-top: 2em;
  color: #e15c64;
}

.latest-update {
  color: #262626;
  text-decoration: underline;
  margin-bottom: 2em;
}

@media screen and (min-width: 250px) {
  .navigation {
    margin: auto;
    max-width: 500px;
    flex-direction: column;
  }
  .nav-btn {
    font-size: 0.8rem;
    margin-bottom: 0.5em;
  }
}

@media screen and (min-width: 780px) {
  .navigation {
    max-width: 100%;
    flex-direction: row;
  }
  .nav-btn {
    font-size: 0.8em;
    margin-bottom: 0;
  }
}
