.hr {
  margin: 0px;
  width: 100%;
  border: 1px solid #262626;
}

.form {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  flex: 1 1 33%;
}

.filters-toolbar {
  padding: 5px 0;
}

.control {
  display: flex;
  align-items: center;
}

.control .label {
  display: inline-block;
  font-size: 16px;
  text-transform: uppercase;
  margin: 5px;
  margin-right: 10px;
  touch-action: manipulation;
}

.book-control {
  margin-top: 0em;
}

.book-control button.primary {
  padding: 0.6em 1.4em;
  background-color: transparent;
  border: 1px solid #aaa;
  font-family: inherit;
  font-size: 16px;
  cursor: pointer;
}

.book-control button.primary:hover {
  transform: scale(0.989);
  background-color: #f2f2f2;
}
.book-control button.primary:active {
  transform: scale(0.975);
}

.select-group-wrapper {
  display: flex;
  border: 1px solid #aaa;
}

.select-group-wrapper input {
  width: 100%;
  padding: 0.6em 1.4em 0.5em 0.8em;
  line-height: 1.3;
  font-size: 16px;
  font-family: inherit;
  font-weight: 700;
  color: #262626;
  border: none;
  border-left: 1px solid#aaa;
}

.select-group-wrapper .select {
  font-size: 16px;
  font-family: inherit;
  font-weight: 700;
  color: #262626;
  line-height: 1.3;
  padding: 0.6em 1.4em 0.5em 0.8em;
  border: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%22292.4%22%20height%3D%22292.4%22%3E%3Cpath%20fill%3D%22%23007CB2%22%20d%3D%22M287%2069.4a17.6%2017.6%200%200%200-13-5.4H18.4c-5%200-9.3%201.8-12.9%205.4A17.6%2017.6%200%200%200%200%2082.2c0%205%201.8%209.3%205.4%2012.9l128%20127.9c3.6%203.6%207.8%205.4%2012.8%205.4s9.2-1.8%2012.8-5.4L287%2095c3.5-3.5%205.4-7.8%205.4-12.8%200-5-1.9-9.2-5.5-12.8z%22%2F%3E%3C%2Fsvg%3E");
  background-repeat: no-repeat, repeat;
  background-position: right 0.7em top 50%, 0 0;
  background-size: 0.65em auto, 100%;
}

.select-css::-ms-expand {
  display: none;
}
.select-css:hover {
  border-color: #888;
}
.select-css:focus {
  border-color: #aaa;
  box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
  box-shadow: 0 0 0 3px -moz-mac-focusring;
  color: #222;
  outline: none;
}
.select-css option {
  font-weight: normal;
}

.search-btn {
  font-family: inherit;
  font-size: 13px;
  background-color: transparent;
  border: none;
  border-left: 1px solid#aaa;
  cursor: pointer;
  padding: 0.5em 1.5em;
}

.search-btn:hover {
  background-color: #f2f2f2;
}

@media screen and (min-width: 300px) {
  .control {
    display: flex;
    flex-direction: column;
  }

  .select-group-wrapper input {
    font-size: 12px;
  }
  .book-control {
    margin-top: 1em;
  }
}

@media screen and (min-width: 620px) {
  .control {
    display: flex;
    flex-direction: row;
  }

  .select-group-wrapper input {
    font-size: 16px;
  }
}

@media screen and (min-width: 765px) {
  .form {
    display: flex;
    flex-direction: row;
  }
  .book-control {
    margin-top: 0em;
    margin-left: 1em;
  }
}
