.container {
  width: 100%;
  margin-top: 3em;
}
/* 
.item-list {
  color: #262626;
}

.main-section {
  margin-bottom: 3.5em;
}

.item {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.9rem;
  cursor: pointer;
}

.item:hover {
  background-color: #262626;
  color: #f7f8fa;
} */
