.btn {
  border: none;
  background-color: transparent;
  fill: green;
}

.btn:active {
  transform: scale(0.9);
}

.table-number-cell {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 745px) {
  .tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .tr td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .tr td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .tr td:last-child {
    border-bottom: 0;
  }

  .table-number-cell {
    display: block;
  }
}
