.navbar {
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  position: fixed;
  top: 0;
  height: 100%;
  padding: 0 16px;
  z-index: 3;
}

.navbar a {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-decoration: none;
  font-size: 17px;
  margin-bottom: 30px;
  color: black;
  word-spacing: -2px;
}

.navbar a:hover {
  text-decoration: underline;
}

.navbar .active {
  color: #e15b64;
}

.navbar a:not(:last-child):after {
  content: "\2022 ";
  width: 6px;
  position: absolute;
  right: -14px;
  bottom: 50%;
  transform: translateY(50%);
  color: #4f6582;
}

@media screen and (min-width: 851px) {
  .navbar {
    width: 100%;
    position: static;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 7em 0 20px 0;
  }
  .navbar a {
    position: relative;
    font-size: 13px;
    margin: 0 0.8em;
  }
}

@media screen and (min-width: 910px) {
  .navbar a {
    font-size: 14px;
  }
}

@media screen and (min-width: 1640px) {
  .navbar {
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    height: 90%;
    width: 21vw;
    padding: 0 50px;
    z-index: 3;
  }

  .navbar a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    font-size: 17px;
    margin-bottom: 30px;
    color: black;
  }
  .navbar a:not(:last-child):after {
    content: "";
  }
}

@media screen and (min-width: 1690px) {
  .navbar {
    position: fixed;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    position: fixed;
    height: 90%;
    width: 21vw;
    padding: 0 50px;
    z-index: 3;
  }

  .navbar a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-decoration: none;
    font-size: 17px;
    margin-bottom: 30px;
    color: #262626;
  }
}
