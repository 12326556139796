.top-nav {
  padding: 1em;
  border-bottom: 1px solid #304557;
  margin-bottom: 4em;
  text-align: center;
}

.nav {
  position: fixed;
  width: 256px;
  top: 0;
  height: 100vh;
  background-color: #051e34;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-attachment: fixed;
  background-image: url("../../assets/images/Admin/nav_nachos@2x.png");
  background-repeat: no-repeat;
  background-position: left 0 bottom 0;
  background-size: 256px 556px;
  color: #dde0e3;
}

.nav-list {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  padding: 1em;
}

.active {
  color: #669df6;
}

.active .label {
  text-decoration: underline;
}

.link:hover {
  background-color: rgba(71, 98, 130, 0.4);
}

.nav-item {
  position: relative;
}

.nav-item .indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15px;
  height: 15px;
  border-radius: 50px;
  position: absolute;
  right: 5%;
  bottom: 39%;
  font-size: 0.7rem;
  background-color: crimson;
  color: #fff;
}

.nav-item span {
  margin-left: 0.5em;
}

.back-btn-container {
  padding: 1em;
}
.back-btn {
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: 1rem;
  border: none;
  text-align: left;
  cursor: pointer;
}

.back-btn span {
  display: inline-block;
  margin-left: 0.5em;
}

.back-btn span:hover {
  text-decoration: underline;
}

@media screen and (max-width: 1060px) {
  .nav {
    display: none;
  }
}
