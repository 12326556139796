.svg {
  width: 45px;
}

@media screen and (min-width: 320px) {
  .svg {
    width: 35px;
  }
}

@media screen and (min-width: 540px) {
  .svg {
    width: 35px;
  }
}
