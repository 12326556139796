.btn-primary {
  display: block;
  border: 0;
  background: none;
  border: 1px solid #000;
  color: #000;
  padding: 0.725em 0.975em;
  cursor: pointer;
  font-size: 0.97rem;
  letter-spacing: 1px;
  margin: auto;
  /* margin-top: 3em; */
  box-shadow: 4px 4px 0px 0px #474646;
}

.btn-primary:active {
  box-shadow: none;
}
