.modal {
  width: 500px;
  height: 500px;
  position: fixed;
  border: 1px solid #262626;
  top: 50%;
  left: 50%;
  padding: 2.5em;
  z-index: 200;
  overflow: hidden;
  background-color: #f7f8fa;
  transition: transform 90ms ease-out;
}

.modalClosed {
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0);
}

.modalOpen {
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.2s ease-out;
}

.backdropClosed {
  visibility: hidden;
  opacity: 0;
}

.backdropOpen {
  visibility: visible;
  opacity: 1;
}

.close-modal {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}

@media screen and (min-width: 280px) {
  .modal {
    width: 300px;
    height: 300px;
    padding: 1.5em;
  }
  .close-modal {
    top: -10px;
    right: -6px;
  }
  .close-modal svg {
    width: 15px;
  }
}

@media screen and (min-width: 470px) {
  .modal {
    width: 450px;
    height: 450px;
    padding: 1.5em;
  }
  .close-modal {
    top: -10px;
    right: -6px;
  }
  .close-modal svg {
    width: 14px;
  }
}

@media screen and (min-width: 725px) {
  .modal {
    width: 500px;
    height: 500px;
    padding: 1.7em;
  }
  .close-modal {
    top: -10px;
    right: -6px;
  }
  .close-modal svg {
    width: 16px;
  }
}
@media screen and (min-width: 950px) {
  .modal {
    width: 550px;
    height: 550px;
    padding: 1.7em;
  }
  .close-modal {
    top: -10px;
    right: -6px;
  }
  .close-modal svg {
    width: 16px;
  }
}
