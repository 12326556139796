.container {
  width: 95%;
  max-width: 900px;
  margin: auto;
  border: 1px solid #dbdbdb;
  display: flex;
  height: 600px;
  margin-top: 2em;
  margin-bottom: 7em;
  border-radius: 3px;
}

.main {
  width: 100%;
  overflow: scroll;
}

.nav {
  flex-basis: 25%;
}

.nav ul {
  display: flex;
  flex-direction: column;
}

@media screen and (min-width: 320px) {
  .container {
    flex-direction: column;
  }

  .nav ul {
    display: flex;
  }

  .nav {
    padding: 0 1em;
    border: none;
  }

  .nav ul a {
    width: 100%;
    padding: 0.2em;
    font-size: 0.7rem;
    text-decoration: none;
    color: #262626;
  }

  .nav ul .active {
    font-weight: 800;

    text-decoration: underline;
  }

  .nav ul a:hover:not(.active) {
    background-color: #eeeeee;
  }
}

@media screen and (min-width: 520px) {
  .nav ul {
    display: flex;
    flex-direction: row;
  }

  .nav ul a {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #262626;
  }

  .nav ul .active {
    font-weight: 800;
    background-color: #acacac;
    text-decoration: none;
  }
}

@media screen and (min-width: 725px) {
  .container {
    flex-direction: row;
  }

  .nav ul {
    display: flex;
    flex-direction: column;
  }

  .nav {
    padding: 0;
    border-right: 1px solid #dbdbdb;
  }

  .nav ul a {
    display: inline;
    text-align: left;
    width: 100%;
    padding: 1em 1.5em;
    font-size: 0.9rem;
    text-decoration: none;
    color: #262626;
    border: none;
    border-left: 2px solid transparent;
  }

  .nav ul .active {
    font-weight: 800;
    background-color: #eeeeee;
    border-left: 2px solid #262626;
  }

  .nav ul a:hover:not(.active) {
    background-color: #eeeeee;
    border-left: 2px solid #bcbcbc;
  }
}
