.opening-hours-outter {
  margin-top: 3.5em;
}

.opening-hours-inner {
  display: grid;
}

.item {
  flex: 0 0 calc(35% - 20px);
}

@media screen and (min-width: 275px) {
  .opening-hours-inner {
    display: grid;
  }
  .opening-hours-inner p {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 500px) {
  .opening-hours-inner p {
    font-size: 1rem;
  }
  .opening-hours-inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
