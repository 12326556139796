.svg {
  width: 20px;
  height: 20px;
  fill: #476282;
  margin-bottom: 0.5em;
  transition: all 500ms ease-in-out;
}

.active {
  fill: #262626;
}
