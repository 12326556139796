.history-item {
  display: flex;
  text-align: left;
  border: 1px solid #dbdbdb;
  margin-bottom: 0.5em;
}

.history-item h3 {
  margin: 0;
}

.history-item img {
  width: 70px;
  height: 110px;
  object-fit: cover;
}

.book-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1em;
  padding: 0.5em;
}

.date {
  margin: 0.3em 0;
}

@media screen and (min-width: 320px) {
  .title {
    font-size: 0.9rem;
  }
  .author {
    font-size: 0.7rem;
  }
  .date {
    font-size: 0.7rem;
  }

  .history-item {
    border-left: none;
    border-right: none;
  }
}

@media screen and (min-width: 725px) {
  .history-item {
    width: 95%;
    margin: 0.5em auto;
    border: 1px solid #dbdbdb;
  }
  .title {
    font-size: 1rem;
  }
  .author {
    font-size: 0.8rem;
  }
  .date {
    font-size: 0.8rem;
  }
}
