.page-wrapper {
  padding: 0 1em;
  margin-top: 2em;
}

.container {
  margin: 0 auto;
  max-width: 750px;
  border: 2px solid #262626;
  margin-bottom: 4em;
  color: #262626;
}
.nav {
  padding: 0.4em 0.5em;
  border-bottom: 1px solid #262626;
  text-align: left;
  background-color: #aaa1ab;
  cursor: pointer;
}

.nav span {
  display: inline-block;
  margin-left: 0.4em;
}

.nav span:hover {
  text-decoration: underline;
}

.title {
  font-size: 18px;
  margin-top: 2em;
}

.inner-container {
  padding: 1em 1em 2.5em 1em;
}

.pickers {
  display: flex;
  justify-content: center;
  margin-bottom: 3em;
}

.control {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 20px 14px 36px;
  width: 200px;
  border: 1px solid #d8d9db;
  background-color: #fff;
}

.control:nth-child(1),
.control:nth-child(2) {
  border-right: none;
}

.label {
  font-size: 0.9rem;
}

.date-picker {
  position: absolute;
  top: 170px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f7f8fa;
  border: 1px solid black;
  z-index: 100;
}

.select {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 10;
  -webkit-appearance: menulist-button;
}

.input-icon {
  position: absolute;
  left: 13px;
}

.date-input {
  border: none;
}

.date-input::-webkit-calendar-picker-indicator {
  background: transparent;
  bottom: 0;
  color: transparent;
  cursor: pointer;
  height: auto;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: auto;
}

input[type="date"]::placeholder {
  font-size: 0.8rem;
}

/* CUSTOMER DETAILS */

.wrapper {
  margin: auto;
  max-width: 600px;
  display: flex;
  margin-bottom: 2em;
}

.user-inputs-wrapper {
  width: 55%;
  padding-right: 1em;
}

.input-control-wrapper {
  max-width: 300px;
  border: 1px solid #d8d9db;
  margin-bottom: 1.3em;
}

.input-control-wrapper input {
  width: 100%;
  height: 100%;
  padding: 14px 20px 14px 15px;
  border: none;
  font-family: inherit;
  font-size: 0.8rem;
}

.fieldset {
  width: 80%;
}

.fieldset legend {
  font-size: 0.8rem;
}

.radio input {
  margin: 0.4rem;
}

.radio label {
  font-size: 0.8rem;
}

/* Info section */

.info-wrapper {
  width: 45%;
}

.reservation-details {
  border-bottom: 1px solid #d8d9db;
}

.reservation-details-title {
  font-size: 1rem;
  text-align: left;
  margin: 0;
}

.reservation-details-item {
  text-align: left;
  font-size: 0.8rem;
  margin-bottom: 0.5em;
}

.reservation-details-item span {
  display: inline-block;
  margin-left: 0.8em;
}

.restaurant-notes {
  text-align: left;
}
.notes-title {
  font-size: 0.9rem;
}

.restaurant-notes p {
  font-size: 0.75rem;
}

.reservation-details-item .invalidDayAlert {
  background-color: crimson;
  color: #fff;
  margin: 0;
}

/* Button */

.button-23 {
  background-color: #ffffff;
  border: 1px solid #222222;
  box-sizing: border-box;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.button-23:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow 0.2s;
}

.button-23:active {
  background-color: #f7f7f7;
  border-color: #000000;
  transform: scale(0.96);
}

.button-23:disabled {
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
}

@media screen and (min-width: 300px) {
  /* PICKERS  */
  .pickers {
    flex-direction: column;
  }

  .control {
    width: 100%;
  }

  .control:nth-child(1),
  .control:nth-child(2) {
    border-right: 1px solid #d8d9db;
  }

  .wrapper {
    flex-direction: column;
  }
  .input-control-wrapper {
    max-width: 100%;
  }

  .user-inputs-wrapper {
    width: 100%;
    padding-right: 0;
  }

  .fieldset {
    width: 100%;
  }

  .info-wrapper {
    width: 100%;
    margin-top: 2em;
  }
}

@media screen and (min-width: 620px) {
  .pickers {
    display: flex;
    flex-direction: row;
  }

  .control {
    width: 200px;
  }

  .control:nth-child(1),
  .control:nth-child(2) {
    border-right: none;
  }
  .wrapper {
    flex-direction: row;
  }

  .input-control-wrapper {
    max-width: 305px;
  }

  .user-inputs-wrapper {
    width: 55%;
    padding-right: 1em;
  }

  .fieldset {
    width: 95%;
  }

  .info-wrapper {
    width: 45%;
    margin-top: 0;
  }
}
