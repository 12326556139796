.book-details {
  margin: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  list-style-type: none;
  border: 2px solid #262626;
}

.book-details-header {
  display: flex;
  border-bottom: 3px solid #262626;
  padding: 1.2em 1.8em;
  background-color: #fee6e3;
}

.bookcover-container {
  width: 128px;
  height: 195px;
  border: 6px solid #fff;
}
.bookcover-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.book-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  padding: 0.9em 1em 0 1em;
}

.book-title {
  margin: 0;
  margin-bottom: 0.4em;
  font-size: 1.2rem;
  font-weight: 800;
}

.book-author,
.copies {
  font-size: 0.9rem;
  margin-bottom: auto;
}

.book-status {
  display: block;
  font-size: 0.8rem;
  padding: 2px 3px;
  margin-top: auto;
  margin-bottom: 0.5em;
  text-align: center;
}

.green {
  color: white;
  background-color: #019267;
}

.red {
  color: white;
  background-color: #e83a14;
}

.synopsis {
  text-align: left;
  padding: 1.2em 1.8em;
  white-space: pre-wrap;
  min-height: 400px;
}

.synopsis h3 {
  text-align: center;
}

.button-80 {
  width: 100%;
  background: transparent;
  backface-visibility: hidden;
  border-style: solid;
  border-width: 2px;
  border-color: #262626;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  margin-top: auto;
  padding: 0 2em;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.3;
  position: relative;
  text-align: center;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  margin-bottom: 0.5em;
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(0.125rem);
}

.button-80:disabled {
  color: #aaa1ab;
  background-color: #ede0df;
  cursor: not-allowed;
}

.secondary {
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.secondary span {
  display: block;
  margin-left: 0.2em;
}

@media screen and (min-width: 320px) {
  .book-details {
    width: 100%;
    margin: auto;
  }
  .book-details-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 0.5em 0.5em;
  }

  .book-title {
    font-size: 1rem;
  }
  .book-info-container {
    padding: 0.6em;
  }

  .synopsis {
    height: auto;
  }
  .button-80 {
    padding: 0 0.7em;
  }

  .secondary span {
    font-size: 0.7rem;
  }

  .synopsis h3 {
    font-size: 0.9em;
  }
  .synopsis p {
    font-size: 0.8em;
  }
}

@media screen and (min-width: 480px) {
  .book-details {
    width: 100%;
  }

  .book-title {
    font-size: 1.2rem;
  }

  .book-details-header {
    padding: 0.5em 0.5em;
    grid-template-columns: 130px auto;
  }
  .book-info-container {
    padding: 0.9em 1em 0 1em;
  }
  .button-80 {
    padding: 0 2em;
  }

  .secondary span {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 620px) {
  .book-details {
    width: 100%;
  }
  .book-details-header {
    padding: 0.5em 0;
    grid-template-columns: 130px auto;
  }
  .book-details-header {
    padding: 1.2em 1.8em;
  }
  .synopsis h3 {
    font-size: 1.5rem;
  }
  .synopsis p {
    font-size: 1rem;
  }
}
