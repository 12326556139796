.months-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-content: center;
  align-items: flex-start;
  gap: 0.4em;
  text-align: center;
}

.month {
  border: 1px solid #262626;
  padding: 0.3em 1em;
  border-radius: 50px;
  font-family: inherit;
  font-size: 13px;
  cursor: pointer;
  background-color: transparent;
  text-transform: uppercase;
}

.month:hover {
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.active {
  background-color: #262626;
  color: #f7f8fa;
}

.month.disabled {
  opacity: 0.65;
  cursor: not-allowed;
}

@media screen and (min-width: 520px) {
  .months-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media screen and (min-width: 650px) {
  .months-container {
    grid-template-columns: repeat(6, 1fr);
  }
}
