.notfound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 80vh;
}

.notfound-404 {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.notfound span {
  font-size: 252px;
}

.notfound h2,
.notfound h3 {
  text-transform: uppercase;
}
