/* BURGER BUTTON ON THE HEADER */

.open-btn {
  width: 26px;
  height: 17px;
  position: absolute;
  left: 25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  transition: all 0.25s ease;
  border: 0;
  background: none;
}

.open-btn span {
  position: absolute;
  height: 1px;
  display: block;
  background-color: #000;
  transition: width 0.2s ease 0ms;
}
.open-btn span.one {
  width: 18px;
  right: 50%;
  top: 0;
  transform: translateX(9px);
}

.open-btn span.two {
  width: 25px;
  right: 0;
  top: 8px;
}

.open-btn span.three {
  width: 10px;
  right: 50%;
  bottom: 0;
  transform: translateX(5px);
}

.nav-open .open-btn {
  opacity: 0;
  visibility: hidden;
}

@media screen and (min-width: 851px) {
  .open-btn {
    display: none;
  }
}
