@import-normalize;
@import url("https://fonts.googleapis.com/css2?family=PT+Mono&display=swap");

*,
*::after,
*::before {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

body {
  margin: 0;
  font-family: "PT Mono", monospace;
  background-color: #f7f8fa;
}

h1,
h2,
h3,
h3 > a,
p,
li,
button {
  font-weight: normal;
  font-style: normal;
}

.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 70%);
  }

  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
