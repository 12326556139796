.modal {
  position: fixed;
  border: 1px solid #262626;
  top: 50%;
  left: 50%;
  z-index: 200;
  overflow: scroll;
  background-color: #f7f8fa;
  transition: transform 90ms ease-out;
}

.modalClosed {
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0);
}

.modalOpen {
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.2s ease-out;
}

.backdropClosed {
  visibility: hidden;
  opacity: 0;
}

.backdropOpen {
  visibility: visible;
  opacity: 1;
}

.close-modal {
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.control .label {
  width: 100%;
  display: inline-block;
  font-size: 18px;
  text-transform: uppercase;
  margin: 5px;
  margin-right: 10px;
  touch-action: manipulation;
}

.control .date-input {
  padding: 0.6em 1.4em 0.5em 0.8em;
  font-family: inherit;
  font-weight: 700;
  font-size: 16px;
  border: 1px solid #aaa;
  width: 100%;
}

.btn-primary {
  background-color: #ff4742;
  color: #fff;
}

.btn-primary:hover {
  background-color: #c92a25;
}

.btn-secondary {
  background-color: #c1c1c1;
  color: #fff;
}
.btn-secondary:hover {
  background-color: #9a9696;
}

@media screen and (min-width: 280px) {
  .modal {
    width: 250px;
    height: auto;
    padding: 2.5em;
  }

  .modal-header {
    border-bottom: 1px solid #717e86;
    text-align: center;
  }
  .modal-header h2 {
    color: #262626;
    font-size: 1rem;
  }

  .modal-body {
    font-size: 0.7rem;
    padding: 1em 0;
    border-bottom: 1px solid #717e86;
    text-align: center;
  }

  .modal-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em 0 0.5em 0;
    margin-top: 2em;
  }
  .modal-actions button {
    border: none;
    padding: 0.4em 0.8em;
    margin: 0 0.3em 0 0.3em;
    cursor: pointer;
    font-size: 0.6rem;
    font-family: inherit;
  }

  .btn-primary {
    background-color: #ff4742;
    color: #fff;
  }

  .btn-secondary {
    background-color: #c1c1c1;
    color: #fff;
  }

  .close-modal {
    top: -6px;
    right: -3px;
  }
  .close-modal svg {
    width: 14px;
  }
}

@media screen and (min-width: 470px) {
  .modal {
    width: 400px;
    height: auto;
    padding: 2.5em;
  }

  .modal-actions button {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 520px) {
  .modal {
    width: 450px;
    height: auto;
    padding: 2em;
  }
  .close-modal {
    top: -6px;
    right: -3px;
  }
  .close-modal svg {
    width: 17px;
  }
}

@media screen and (min-width: 725px) {
  .modal {
    width: 600px;
    height: auto;
    padding: 2.5em;
  }

  .modal-header {
    border-bottom: 1px solid #717e86;
  }
  .modal-header h2 {
    text-align: left;
    font-size: 1.2rem;
    color: #262626;
  }

  .modal-body {
    padding: 2em 0;
    border-bottom: 1px solid #717e86;
  }

  .modal-body p {
    text-align: left;
    font-size: 0.9rem;
  }
  .modal-actions {
    display: flex;
    justify-content: center;
    padding: 1.5em 0 0.2em 0;
    margin-top: 2em;
  }
  .modal-actions button {
    border: none;
    padding: 0.8em 1.8em;
    margin: 0 0.3em 0 0.3em;
    cursor: pointer;
    font-size: 0.9rem;
    font-family: inherit;
  }

  .close-modal {
    top: -2px;
    right: 3px;
  }
  .close-modal svg {
    width: 17px;
  }
}
