.page-wrapper {
  padding: 0 1em;
  margin-top: 2em;
}

.container {
  margin: 0 auto;
  max-width: 750px;
  border: 2px solid #262626;
  margin-bottom: 4em;
  color: #262626;
}
.nav {
  padding: 0.4em 0.5em;
  border-bottom: 1px solid #262626;
  text-align: left;
  background-color: #aaa1ab;
  cursor: pointer;
}

.nav span {
  display: inline-block;
  margin-left: 0.4em;
}

.nav span:hover {
  text-decoration: underline;
}

.title {
  font-size: 18px;
  margin-top: 2em;
}

.inner-container {
  padding: 1em 1em 2.5em 1em;
}

.picker {
  display: flex;
  justify-content: center;
  margin-bottom: 1.3em;
}

.control {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 14px 20px 14px 36px;
  border: 1px solid #d8d9db;
  background-color: #fff;
}

.label {
  font-size: 0.9rem;
}

.select {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: 10;
  -webkit-appearance: menulist-button;
}

.input-icon {
  position: absolute;
  left: 13px;
}

/* CUSTOMER DETAILS */

.user-inputs-wrapper {
  width: 100%;
}

.input-control-wrapper {
  max-width: 520px;
  border: 1px solid #d8d9db;
  margin-bottom: 1.3em;
}

.form {
  max-width: 520px;
  margin: auto;
}

.input-control-wrapper input {
  width: 100%;
  height: 100%;
  padding: 14px 20px 14px 15px;
  border: none;
  font-family: inherit;
  font-size: 0.8rem;
}

.fieldset {
  width: 100%;
  margin-bottom: 1em;
}

.fieldset legend {
  font-size: 0.8rem;
}

.radio input {
  margin: 0.4rem;
}

.radio label {
  font-size: 0.8rem;
}

/* Button */

.button-23 {
  background-color: #ffffff;
  border: 1px solid #222222;
  box-sizing: border-box;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  outline: none;
  padding: 13px 23px;
  position: relative;
  text-align: center;
  text-decoration: none;
  touch-action: manipulation;
  transition: box-shadow 0.2s, -ms-transform 0.1s, -webkit-transform 0.1s, transform 0.1s;
  user-select: none;
  -webkit-user-select: none;
  width: auto;
}

.button-23:focus-visible {
  box-shadow: #222222 0 0 0 2px, rgba(255, 255, 255, 0.8) 0 0 0 4px;
  transition: box-shadow 0.2s;
}

.button-23:active {
  background-color: #f7f7f7;
  border-color: #000000;
  transform: scale(0.96);
}

.button-23:disabled {
  border-color: #dddddd;
  color: #dddddd;
  cursor: not-allowed;
  opacity: 1;
}
