.item-list {
  color: #262626;
}

.item {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  cursor: pointer;
  margin-bottom: 1em;
}
.item span {
  margin-top: 10px;
}

.section {
  margin-bottom: 3.5em;
}

@media (hover: hover) {
  .item:hover {
    background-color: #262626;
    color: #f7f8fa;
  }
}

@media screen and (min-width: 320px) {
  .item {
    font-size: 0.7rem;
  }
  .category-title {
    font-size: 1rem;
  }
}

@media screen and (min-width: 520px) {
  .item {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 645px) {
  .item-list {
    display: none;
  }
}
