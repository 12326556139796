.page-wrapper {
  padding: 0 1em;
  height: 70vh;
}

.container {
  margin: 0 auto;
  max-width: 550px;
  min-height: 300px;
  border: 2px solid #262626;
  color: #262626;
  margin-top: 4em;
}

.nav {
  padding: 0.4em 0.5em;
  border-bottom: 1px solid #262626;
  text-align: left;
  background-color: #aaa1ab;
  cursor: pointer;
}

.nav span {
  display: inline-block;
  margin-left: 0.4em;
}

.nav span:hover {
  text-decoration: underline;
}

.body {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 300px;
  padding: 1em;
}

.interview-details {
  display: flex;
  flex-direction: column;
}

.interview-details span {
  margin-bottom: 0.2em;
  color: #dd565e;
}

@media screen and (min-width: 850px) {
  .page-wrapper {
    height: 63vh;
  }
}

@media screen and (min-width: 1640px) {
  .page-wrapper {
    height: 70vh;
  }
}
