.hr {
  margin: 0px;
  width: 100%;
  border: 1px solid #262626;
}

.filters-toolbar {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.control-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  flex: 1 1 33%;
}

.control .label {
  display: inline-block;
  font-size: 18px;
  text-transform: uppercase;
  margin: 5px;
  margin-right: 10px;
  touch-action: manipulation;
}

.select-group-wrapper {
  display: inline-block;
  position: relative;
  z-index: 2;
}

.select {
  position: relative;
  background-color: transparent;
  border: 1px solid transparent;
  font-family: inherit;
  font-size: 18px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 253px;
  height: 55px;
  padding: 10px 20px;
  color: #77a4bd;
  background-color: transparent;
  -webkit-appearance: inherit;
  -moz-appearance: none;
  appearance: none;
  -webkit-font-smoothing: antialiased;
}

.icon-chevron-down {
  width: calc(10em / 16);
  height: calc(10em / 16);
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 8px;
  z-index: -1;
}

@media screen and (min-width: 320px) {
  .filters-toolbar-wrapper,
  .filters-toolbar-wrapper .label {
    font-size: 0.9rem;
  }

  .filters-toolbar {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    padding: 5px 0;
  }

  .control {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (min-width: 730px) {
  .filters-toolbar-wrapper,
  .filters-toolbar-wrapper .label {
    font-size: 1rem;
  }

  .filters-toolbar {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    padding: 0;
  }

  .control {
    margin: 0;
    display: flex;
    flex-direction: row;
  }
}
