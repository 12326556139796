.flash-message {
  position: fixed;
  z-index: 1100;
  top: 62px;
  right: 12px;
  max-width: 100%;
  opacity: 0;
  padding: 1.5em 3.2em;
  font-size: 0.7rem;
  transform: translateY(-16px);
  transition: all 0.5s;
  transition-timing-function: cubic-bezier(0.3, 0, 0, 2);
}

.success {
  opacity: 1;
  transform: translateY(0);
  background-color: #019267;
  color: #fff;
}

.fail {
  opacity: 1;
  transform: translateY(0);
  background-color: #e15b64;
  color: #fff;
}
