.svg {
  width: 25px;
}

@media screen and (min-width: 250px) {
  .svg {
    width: 20px;
  }
}

@media screen and (min-width: 590px) {
  .svg {
    width: 25px;
  }
}
