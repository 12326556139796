.records {
  font-size: 0.9rem;
  border-collapse: collapse;
  width: 100%;
  margin-top: 1em;
}

.records td,
.records th {
  border: 1px solid #ddd;
  padding: 8px;
}

.records tr:nth-child(even) {
  background-color: #f2f2f2;
}

.recordstr:hover {
  background-color: #ddd;
}

.records th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #1967d2;
  color: white;
}

@media screen and (max-width: 600px) {
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
