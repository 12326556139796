/* CSS */
.button-16 {
  /* position: absolute;
  top: 30%;
  left: 15px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e1ecf4;
  border: 1px solid #f8f9fa;
  border-radius: 4px;
  color: #3c4043;
  cursor: pointer;
  font-family: arial, sans-serif;
  font-size: 14px;
  height: 36px;
  line-height: 27px;
  min-width: 75px;
  padding: 0 16px;
  text-align: center;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: pre;
}

.button-16:hover {
  border-color: #dadce0;
  box-shadow: rgba(0, 0, 0, 0.1) 0 1px 1px;
  color: #202124;
}

.button-16:focus {
  border-color: #4285f4;
  outline: none;
}

@media screen and (min-width: 275px) {
  .button-16 {
    position: fixed;
    bottom: 20px;
    left: 20px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
}

@media screen and (min-width: 850px) {
  .button-16 {
    position: fixed;
    top: 28px;
    left: 20px;
    box-shadow: none;
  }
}
@media screen and (min-width: 1280px) {
  .button-16 {
    position: fixed;
    top: 28px;
    left: 70px;
    box-shadow: none;
  }
}

@media screen and (min-width: 1920px) {
  .button-16 {
    position: fixed;
    top: 28px;
    left: 170px;
    box-shadow: none;
  }
}
