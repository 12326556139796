.event-card {
  position: relative;
  background-color: #fffffe;
  border: 3px solid #000;
  overflow: hidden;
  margin: 0.5em;
}

.event-image {
  height: 200px;
  cursor: pointer;
}

.event-image img {
  display: block;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.event-body {
  position: relative;
  font-size: 0.7rem;
  text-align: left;
  padding: 0.5em;
  background-color: #f7f8fa;
  height: 100%;
}
.event-title {
  margin: 0.5em 0;
}
.event-genres {
  margin-top: 0.5em;
}

.event-genres span {
  display: inline-block;
  border: 1px solid #000;
  margin-right: 0.4em;
  padding: 2px 5px;
}

.event-date,
.overlay-date {
  margin-bottom: 1em;
}

.event-date span,
.overlay-date span {
  display: block;
}

.event-description {
  white-space: pre-wrap;
}

/* OVERLAY ON PROJECTS CARDS */

.overlay {
  position: absolute;
  background-color: #292929;
  color: #fcfefd;
  top: 100%;
  width: 100%;
  height: 100%;
  transition: 0.3s cubic-bezier(0.37, 0, 0.63, 1);
  z-index: 10;
  overflow: scroll;
}

.show-overlay {
  top: 0%;
}

.overlay-body {
  position: relative;
  font-size: 0.7rem;
  text-align: left;
  padding: 0.5em;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 10px;
  margin-right: 15px;
  cursor: pointer;
}

.overlay-title {
  margin: 0.5em 0;
}

.overlay .event-genres span {
  border: 1px solid #fff;
}

@media screen and (min-width: 250px) {
  .event-card {
    flex-basis: 300px;
  }
}

@media screen and (min-width: 930px) {
  .event-card {
    flex-basis: 30%;
  }
}
