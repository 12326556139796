.dropdown {
  position: relative;
  top: 54px;
  right: 100px;
  width: 300px;
  height: auto;
  filter: drop-shadow(rgba(99, 99, 99, 0.2) 0px 2px 4px);
  background-color: #fafafa;
}

.dropdown:after {
  content: "";
  position: absolute;
  top: -20px;
  left: 81%;
  border-width: 10px;
  border-style: solid;
  border-color: transparent transparent #f7f8fa transparent;
}

.dropdown ul {
  display: flex;
  flex-direction: column;
}

.dropdown-item:last-child {
  border-top: 1px solid #dbdbdb;
}
.dropdown-item a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #262626;
  padding: 0.8em 1em;
}

.dropdown-item a:hover {
  background-color: #eeeeee;
}

@media screen and (min-width: 275px) {
  .dropdown {
    right: 10px;
  }
}

@media screen and (min-width: 1280px) {
  .dropdown {
    right: 65px;
  }
}
@media screen and (min-width: 1920px) {
  .dropdown {
    right: 155px;
  }
}
