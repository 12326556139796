.galleryCarousel {
  margin: 0 auto;
  max-width: 750px;
  width: 100%;
  height: 370px;
  margin-bottom: 15em;
  margin-top: 4em;
}

.galleryCarousel .gallery-cell {
  max-width: 100%;
  padding: 0 2px;
}
.galleryCarousel .gallery-cell:hover {
  cursor: pointer;
}

.galleryCarousel .gallery-cell img {
  height: 350px;
  max-width: 100%;
  object-fit: cover;
  border: none;
}

.galleryCarousel .flickity-button {
  display: none;
}

@media screen and (min-width: 768px) {
  .galleryCarousel {
    display: none;
  }
}
