.btn {
  border: none;
  background-color: transparent;
}

.btn:active {
  transform: scale(0.9);
}

@media screen and (max-width: 800px) {
  .tr {
    border-bottom: 3px solid #ddd;
    display: block;
    margin-bottom: 0.625em;
  }

  .tr td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: 0.8em;
    text-align: right;
  }

  .tr td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  .tr td:last-child {
    border-bottom: 0;
  }
}
