.container {
  margin-top: 1.5em;
  padding: 0 1em;
  color: #262626;
}

.background {
  position: relative;
  max-width: 950px;
  height: 400px;
  margin: 0 auto;
  border-radius: 5px;
}

.header-image {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 25px;
  left: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 5px solid #fff;
}

.body {
  max-width: 900px;
  padding: 0 1em;
}

.headline {
  text-align: left;
}

.headline a {
  color: #e15b64;
}
.body p {
  line-height: 1.5;
}

.events-container {
  /* min-height: 650px; */
}

@media screen and (min-width: 275px) {
  .header-image {
    max-width: 400px;
    position: static;
    box-shadow: none;
    width: 100%;
    border: none;
    height: 0;
    margin: auto;
  }
  .background {
    background-image: none;
    height: 230px;
  }

  .body {
    margin: 7em auto;
  }
  .headline h1 {
    font-size: 1.3rem;
  }
  .headline p {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 350px) {
  .body {
    margin-top: 13.5rem;
  }
}
@media screen and (min-width: 430px) {
  .body {
    background-image: none;
  }
}

@media screen and (min-width: 540px) {
  .header-image {
    position: absolute;
    width: 400px;
    height: 400px;
    background-size: contain;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 5px solid #fff;
  }

  .background {
    background-size: cover;
    background-position: center;
    background-image: url("../../assets/images/eventsBG.jpg");
    max-width: 950px;
    height: 400px;
  }
  .body {
    margin: 7em auto;
  }
  .headline h1 {
    font-size: 2rem;
  }
  .headline p {
    font-size: 1rem;
  }
}
