.container {
  margin-top: 1.5em;
  padding: 0 1em;
  color: #262626;
}

.background {
  position: relative;
  max-width: 950px;
  height: 400px;
  margin: 0 auto;
  border-radius: 5px;
}

.header-image {
  position: absolute;
  width: 400px;
  height: 400px;
  top: 25px;
  left: 60px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  border: 5px solid #fff;
}

.body {
  max-width: 850px;
  margin: 5em auto;
  padding: 0 1em;
}

.body a {
  color: #e15b64;
}

.body h1 {
  margin-bottom: 0.7em;
}

.body p {
  margin-bottom: 1em;
  line-height: 1.5;
}

.address {
  margin-top: 1em;
}
.body section:nth-child(2) p {
  margin: 0;
}

.body section:nth-child(2) a {
  display: inline-block;
  margin-bottom: 1em;
}

.body a:hover {
  text-decoration: none;
}

.body .btn {
  display: inline-block;
  border: 1px solid #262626;
  color: #262626;
  text-decoration: none;
  font-family: inherit;
  font-size: 1em;
  padding: 2px 14px;
  margin-top: 0.5em;
}
.btn:hover {
  background-color: #262626;
  color: #f7f8fa;
}

.social {
  margin-top: 1em;
}
.social .btn:first-child {
  margin-right: 1em;
}

.opening-hours-inner {
  display: grid;
}

@media screen and (min-width: 275px) {
  .header-image {
    max-width: 400px;
    position: static;
    box-shadow: none;
    background-size: contain;
    width: 100%;
    height: 0;
    border: none;
    margin: auto;
  }
  .background {
    background-image: none;
    height: 330px;
  }

  .body {
    margin: auto;
    margin-bottom: 7em;
  }
  .body h1 {
    font-size: 1.3rem;
  }
  .body p {
    font-size: 0.8rem;
  }

  .body a {
    font-size: 0.8rem;
  }
  .opening-hours-inner {
    display: grid;
  }
}

@media screen and (min-width: 350px) {
  .body {
    margin-top: 6.5rem;
  }
}

@media screen and (min-width: 540px) {
  .header-image {
    position: absolute;
    width: 400px;
    height: 400px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    border: 5px solid #fff;
  }

  .background {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-image: url("../../assets/images/bgNoise.jpg");
    height: 400px;
  }

  .body {
    margin: 7em auto;
    text-align: left;
  }
  .body p {
    font-size: 1rem;
  }

  .body a {
    font-size: 1rem;
  }
  .opening-hours-inner {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  .body h1 {
    font-size: 2rem;
  }
}
