.header {
  padding: 0 1.2em;
}

@media screen and (min-height: 200px) {
  .header {
    margin-bottom: -7em;
  }
}

@media screen and (min-height: 500px) {
  .header {
    margin-bottom: 4em;
  }
}
@media screen and (min-height: 800px) {
  .header {
    margin-bottom: 7em;
  }
}

@media screen and (min-height: 980px) {
  .header {
    margin-bottom: 14em;
  }
}
