.btn-signin {
  width: 120px;
  height: 120px;
  display: flex;
  align-items: center;
  flex-direction: column;
  border: 1px solid #000;
  background-color: transparent;
  padding: 1.4em 1.4em;
  margin: 0 0.5em 1em 0.5em;
  cursor: pointer;
  font-family: inherit;
  font-weight: 800;
  color: #000;
}

.btn-signin span {
  width: 60px;
  font-size: 0.8rem;
}
.btn-signin i {
  margin-bottom: 0.4em;
}

.btn-signin:active {
  transform: scale(0.98);
}

@media (hover: hover) {
  .btn-signin:hover {
    background-color: #262626;
    color: #f7f8fa;
  }
  .btn-signin:hover svg {
    fill: #f7f8fa;
  }
}

@media screen and (min-width: 280px) {
  .btn-signin {
    width: 80px;
    height: 80px;
  }
  .btn-signin span {
    width: 60px;
    font-size: 0.5rem;
  }
}

@media screen and (min-width: 620px) {
  .btn-signin {
    width: 120px;
    height: 120px;
  }
  .btn-signin span {
    width: 80px;
    font-size: 0.8rem;
  }
}
