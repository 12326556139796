.container {
  margin: 0 auto;
}

@media screen and (min-width: 280px) {
  .container {
    width: 100%;
    height: auto;
  }

  .header {
    border-bottom: 1px solid #717e86;
    text-align: center;
  }
  .header h2 {
    color: #262626;
    font-size: 1rem;
  }

  .body {
    font-size: 0.7rem;
    padding: 1em 0;
    text-align: center;
  }
}

@media screen and (min-width: 470px) {
  .container {
    border: 1px solid #262626;
    background-color: #f7f8fa;
    padding: 2.5em;
  }
}

@media screen and (min-width: 725px) {
  .container {
    width: 600px;
    height: auto;
    padding: 2.5em;
  }
}
