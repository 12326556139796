.browse-btn {
  display: block;
  margin: 2em auto;
  border: none;
  padding: 0;
  background-color: transparent;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
  color: #262626;
}

.records-carousel-container {
  padding: 0.5em 0;
  margin-bottom: 2em;
  margin: auto;
  max-width: 450px;
}

.svg {
  width: 20px;
  height: 21px;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.record-list-container {
  min-height: 591px;
}
.record-list-container .no-result {
  text-align: center;
}

@media screen and (min-width: 320px) {
  .svg {
    width: 15px;
    height: 16px;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}

@media screen and (min-width: 620px) {
  .svg {
    width: 20px;
    height: 21px;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }
}
