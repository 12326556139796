.checkboxList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5em;
  margin-top: 1em;
  overflow: auto;
  text-align: center;
}

.checkbox {
  margin-right: 5px;
}

@media screen and (min-width: 600px) {
  .checkboxList {
    grid-template-columns: repeat(3, 1fr);
    text-align: left;
  }
}

@media screen and (min-width: 710px) {
  .checkboxList {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 910px) {
  .checkboxList {
    grid-template-columns: repeat(6, 1fr);
  }
}
