.books-carousel-container {
  max-width: 400px;
  margin: auto;
}

.book-list-container {
  min-height: 591px;
}

.browse-btn {
  display: block;
  margin: 2em auto;
  border: none;
  padding: 0;
  background-color: transparent;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
  color: #262626;
}

.button-80 {
  background: #fff;
  backface-visibility: hidden;
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 0.125rem;
  border-color: #262626;
  box-sizing: border-box;
  color: #262626;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  align-items: center;
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 100;
  letter-spacing: -0.01em;
  line-height: 1.3;
  margin-bottom: 1em;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(0.125rem);
}

.books-carousel-container {
  padding: 0.5em 0;
  margin-bottom: 2em;
}

.svg {
  width: 20px;
  height: 21px;
  right: 20px;
  top: 20px;
  cursor: pointer;
}

.book-list-container .no-result {
  text-align: center;
}

@media screen and (min-width: 320px) {
  .svg {
    width: 15px;
    height: 16px;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .button-80 {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 620px) {
  .svg {
    width: 20px;
    height: 21px;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .button-80 {
    font-size: 0.9rem;
  }
}
