.events-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.controls input {
  background-color: #e6e6e6;
  height: 36px;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
  font-family: inherit;
  font-size: 0.9rem;
}

.textarea-control textarea {
  background-color: #e6e6e6;
  border-radius: 5px;
  border: none;
  font-size: 0.9rem;
}

.controls input::placeholder,
.textarea-control textarea::placeholder {
  color: #656565;
}

.controls input:focus,
.textarea-control textarea:focus {
  background-color: #fff;
  outline: 2px solid #1967d2;
}
.controls input:focus::placeholder {
  color: #9e9e9e;
}
.upload-file-controls {
  text-align: left;
  margin-bottom: 2em;
}

.upload-file-controls small {
  display: block;
  font-size: 0.7rem;
  margin-bottom: 1em;
}
.uploadLabel {
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.3em;
}

/* Create event button */
.button-24 {
  background: #ff4742;
  border: 1px solid #ff4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #ff4742;
}

.button-24:active {
  opacity: 0.5;
}

.file-alert {
  margin-top: 0.5em;
}

.file-alert small {
  margin: 0;
}

@media screen and (min-width: 250px) {
  .controls {
    margin: 1em 0;
  }
  .controls input {
    width: 100%;
  }
  .controls label {
    text-align: left;
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.5em;
  }

  .controls select {
    width: 100%;
    height: 30px;
    -webkit-appearance: menulist-button;
    font-size: 0.9rem;
  }
  .date-time-controls {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .date-controls,
  .time-controls {
    flex-basis: 48%;
    max-width: 48%;
  }
  .date-time-controls input {
    width: 100%;
    height: 30px;
    -webkit-appearance: none;
    border: 1px solid #262626;
    font-size: 0.6rem;
    padding: 0 10px;
  }
  .date-time-controls label {
    text-align: left;
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.5em;
  }

  .description-date,
  .description-time {
    font-size: 0.6rem;
  }

  .textarea-control label {
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.5em;
  }

  .textarea-control textarea {
    width: 100%;
    min-height: 200px;
    resize: none;
    padding: 10px;
    margin-bottom: 1em;
    font-family: inherit;
  }
}

@media screen and (min-width: 460px) {
  .form {
    border: 1px solid #262626;
    background-color: #f7f8fa;
    padding: 2.5em;
  }
  .date-time-controls input {
    font-size: 0.9rem;
  }
}
