.book-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style-type: none;
  border: 2px solid #262626;
}
.bookcover-container {
  width: 128px;
  height: 195px;
  border: 4px solid #fff;
}
.bookcover-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.book-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  padding: 0.9em 0.6em;
}

.book-title {
  margin: 0;
  margin-bottom: 0.4em;
  font-size: 0.9rem;
  font-weight: 800;
}

.book-author {
  font-size: 0.8rem;
  margin-bottom: 0.3em;
  margin-bottom: auto;
}

.book-status {
  display: block;
  font-size: 0.8rem;
  padding: 2px 3px;
  text-align: center;
  margin: auto;
  margin-bottom: 0.5em;
}

.green {
  color: white;
  background-color: #019267;
}

.red {
  color: white;
  background-color: #e15b64;
}

.button-80 {
  background: transparent;
  backface-visibility: hidden;
  border-style: solid;
  border-width: 2px;
  border-color: #262626;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  margin-top: auto;
  padding: 0 2em;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 100;
  letter-spacing: -0.01em;
  line-height: 1.3;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(0.125rem);
}
