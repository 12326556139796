.nav-container {
  display: inline-block;
}

.navigation {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 2em 0;
  margin-top: 2em;
}

.navigation a {
  margin-bottom: 0.2em;
}

.nav-btn {
  border: none;
  border: 1px solid #262626;
  cursor: pointer;
  font-family: inherit;
  font-size: 0.8em;
  background-color: transparent;
  margin-bottom: 0.2em;
}

.active {
  color: #f7f8fa;
  background-color: #262626;
}

/* CLOSE BOOK CATEGORIES BUTTON */

.close-btn {
  position: absolute;
  left: 50%;
  top: 0;
  width: 15px;
  height: 15px;
  cursor: pointer;
  transform: translateX(-50%);
}

.close-btn span {
  display: block;
  width: 100%;
  height: 1px;
  left: 0;
  top: 7px;
  background-color: #000;
  position: absolute;
}

.close-btn span.one {
  transform: rotate(-45deg);
}
.close-btn span.two {
  transform: rotate(45deg);
}
