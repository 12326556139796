.account {
  text-align: left;
  margin-left: 1em;
}

.controls {
  text-align: left;
  margin: 1em;
}

.controls input {
  background-color: #e6e6e6;
  height: 36px;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
  font-family: inherit;
  font-size: 0.9rem;
}

.controls input::placeholder {
  color: #656565;
}

.controls input:focus {
  background-color: #fff;
  outline: 2px solid #1967d2;
}
.controls input:focus::placeholder {
  color: #9e9e9e;
}

.controls label {
  text-align: left;
  display: block;
  font-size: 0.8rem;
  margin-bottom: 0.5em;
}
.error {
  display: block;
  color: red;
}

.button-80 {
  background: transparent;
  backface-visibility: hidden;
  border-style: solid;
  border-width: 2px;
  border-color: #262626;
  color: #262626;
  cursor: pointer;
  padding: 0 2em;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 100;
  letter-spacing: -0.01em;
  line-height: 1.3;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  display: block;
  margin-left: 1.5em;
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(0.125rem);
}

.flex {
  display: flex;
  align-items: center;
}
