.upper-toolbar {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.buttons-container {
  display: flex;
}

.btn {
  align-self: center;
  font-family: inherit;
  font-size: 13px;
  background-color: transparent;
  border: none;
  border: 1px solid#aaa;
  cursor: pointer;
  padding: 0.5em 1.8em;
  white-space: nowrap;
}

.btn:hover {
  background-color: #f2f2f2;
}

.toolbar-container {
  padding: 5px 0;
  border-top: 2px solid #262626;
  border-bottom: 2px solid #262626;
}

@media screen and (min-width: 765px) {
  .upper-toolbar {
    display: flex;
    flex-direction: row;
  }
}
