.form {
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid #262626;
  background-color: #f7f8fa;
  padding: 2.5em;
  flex: 1;
}

.controls textarea {
  width: 100%;
  min-height: 200px;
  resize: none;
  padding: 10px;
  background-color: #e6e6e6;
  border-radius: 5px;
  border: none;
  font-size: 0.9rem;
  font-family: inherit;
}
.controls textarea::placeholder {
  color: #656565;
}

.controls input {
  background-color: #e6e6e6;
  height: 36px;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
  font-family: inherit;
  font-size: 0.9rem;
}

.file-control {
  background-color: #e6e6e6;
  height: 36px;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
  font-family: inherit;
  font-size: 0.9rem;
  text-align: left;
}

.controls input::placeholder {
  color: #656565;
}

.controls input:focus,
.controls textarea:focus {
  background-color: #fff;
  outline: 2px solid #1967d2;
}
.controls input:focus::placeholder {
  color: #9e9e9e;
}

.action {
  margin-top: 3em;
}

/* Reservation button */
.button-24 {
  background: #ff4742;
  border: 1px solid #ff4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #ff4742;
}

.button-24:active {
  opacity: 0.5;
}

@media screen and (min-width: 250px) {
  .controls {
    margin: 1em 0;
  }
  .controls input {
    width: 100%;
  }
  .controls label {
    text-align: left;
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.5em;
  }
}

/* BOOK ITEM TEST FOR IMAGE UPLOAD  */
/* .book-item {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  list-style-type: none;
  border: 2px solid #262626;
}
.bookcover-container {
  width: 128px;
  height: 195px;
  border: 4px solid #fff;
}
.bookcover-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  object-position: center;
}
.book-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  padding: 0.9em 0.6em;
}

.book-title {
  margin: 0;
  margin-bottom: 0.4em;
  font-size: 0.9rem;
  font-weight: 800;
}

.book-author {
  font-size: 0.8rem;
  margin-bottom: 0.3em;
  margin-bottom: auto;
}

.book-status {
  display: block;
  font-size: 0.8rem;
  padding: 2px 3px;
  text-align: center;
  margin: auto;
  margin-bottom: 0.5em;
}

.green {
  color: white;
  background-color: #019267;
}

.red {
  color: white;
  background-color: #e15b64;
}

.button-80 {
  background: transparent;
  backface-visibility: hidden;
  border-style: solid;
  border-width: 2px;
  border-color: #262626;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  margin-top: auto;
  padding: 0 2em;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 100;
  letter-spacing: -0.01em;
  line-height: 1.3;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(0.125rem);
} */
