.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 200;
  overflow: scroll;
  background-color: #f7f8fa;
  transition: transform 90ms ease-out;
}

.modalClosed {
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0);
}

.modalOpen {
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.2s ease-out;
}

.backdropClosed {
  visibility: hidden;
  opacity: 0;
}

.backdropOpen {
  visibility: visible;
  opacity: 1;
}

.close-modal {
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}

@media screen and (min-width: 280px) {
  .modal {
    width: 95%;
    height: 400px;
  }

  .close-modal {
    top: -6px;
    right: -3px;
  }
  .close-modal svg {
    width: 14px;
  }
}

@media screen and (min-width: 470px) {
  .modal {
    width: 400px;
  }
}

@media screen and (min-width: 520px) {
  .modal {
    width: 450px;
    min-height: 600px;
  }
  .close-modal {
    top: -6px;
    right: -3px;
  }
  .close-modal svg {
    width: 17px;
  }
}

@media screen and (min-width: 770px) {
  .modal {
    width: 600px;
  }

  .close-modal {
    top: -2px;
    right: 3px;
  }
  .close-modal svg {
    width: 17px;
  }
}
