.record-details {
  margin: auto;
  display: grid;
  grid-template-rows: 1fr auto;
  list-style-type: none;
  border: 2px solid #262626;
}

.record-details-header {
  display: flex;
  border-bottom: 3px solid #262626;
  padding: 1.2em 1.8em;
  background-color: #fee6e3;
}

.recordcover-container {
  width: 250px;
  height: 250px;
  border: 6px solid #fff;
}
.recordcover-container img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.record-info-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  text-align: left;
  padding: 0.9em 1em 0 1em;
}

.record-title {
  margin: 0;
  margin-bottom: 0.4em;
  font-size: 1.2rem;
  font-weight: 800;
}

.record-author {
  display: block;
  font-size: 0.9rem;
}

.nav-tab {
  margin-top: 1em;
  text-align: center;
}

.tab-button {
  border: 1px solid #262626;
  cursor: pointer;
  padding: 1em 1.5em;
  font-family: inherit;
  font-weight: 800;
  background-color: transparent;
  color: #262626;
}

.active {
  background-color: #aaa1ab;
}

.tab-button:active {
  transform: scale(0.975);
}

.container {
  text-align: left;
  padding: 1.2em 1.8em;
  white-space: pre-wrap;
  min-height: 400px;
}
.container h3 {
  text-align: center;
}

.tracklist .track {
  font-size: 0.9em;
  text-align: center;
}

.button-80 {
  background: transparent;
  backface-visibility: hidden;
  border-style: solid;
  border-width: 2px;
  border-color: #262626;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  margin-top: auto;
  padding: 1em 3.5em;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 700;
  letter-spacing: -0.01em;
  line-height: 1.3;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(0.125rem);
}

.button-80:disabled {
  color: #aaa1ab;
  background-color: #ede0df;
  cursor: not-allowed;
}

@media screen and (min-width: 320px) {
  .record-details {
    width: 100%;
    margin: auto;
  }
  .record-details-header {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 1em;
  }
  .recordcover-container {
    width: 150px;
    height: 150px;
    overflow: scroll;
  }
  .record-title {
    font-size: 0.8rem;
  }
  .record-author {
    font-size: 0.7rem;
  }

  .record-info-container {
    padding: 0.5em 0 0 0.4em;
  }
  .button-80 {
    padding: 0;
  }
  .container {
    height: auto;
  }

  .container h3 {
    font-size: 0.9em;
  }
  .container p {
    font-size: 0.8em;
  }

  .tab-button {
    padding: 0.5em 1em;
    font-size: 10px;
  }
}

@media screen and (min-width: 770px) {
  .record-details {
    width: 100%;
  }

  .record-details-header {
    padding: 0.5em 0;
    grid-template-columns: repeat(2, 260px);
  }
  .record-details-header {
    padding: 1.2em 1.8em;
  }

  .recordcover-container {
    width: 250px;
    height: 250px;
  }
  .record-title {
    font-size: 1.5rem;
  }
  .record-author {
    font-size: 1rem;
  }

  .record-info-container {
    padding: 0.9em 1em 0.1em 0em;
  }
  .button-80 {
    padding: 1em 3.5em;
  }

  .container h3 {
    font-size: 1.5rem;
  }
  .container p {
    font-size: 1rem;
  }
  .tab-button {
    padding: 1em 1.5em;
    font-size: 14px;
  }
}
