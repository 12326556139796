.svg {
  width: 15px;
  cursor: pointer;
}

@media screen and (max-width: 1260px) {
  .svg {
    width: 12px;
  }
}
