.checkboxList {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 0.5em;
  margin-top: 1em;
  text-align: center;
  font-size: 12px;
}
.label {
  display: flex;
  align-items: center;
}

.checkbox {
  margin-right: 5px;
}

@media screen and (min-width: 600px) {
  .checkboxList {
    grid-template-columns: repeat(2, 1fr);
    text-align: left;
  }
}

@media screen and (min-width: 840px) {
  .checkboxList {
    grid-template-columns: repeat(3, 1fr);
  }
}
