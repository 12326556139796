.member-details {
  display: flex;
}

@media screen and (min-width: 320px) {
  .member-details {
    flex-direction: column;
    text-align: left;
  }

  .member-data {
    margin-bottom: 1em;
  }

  .member-data:nth-child(2) {
    margin-left: 0;
  }
}

@media screen and (min-width: 520px) {
  .member-details {
    flex-direction: row;
    text-align: left;
  }
  .member-data:nth-child(2) {
    margin-left: 2.5em;
  }
}
