.container {
  margin: auto;
  margin-bottom: 3em;
}

.container a {
  text-decoration: none;
}

.container .nav {
  display: flex;
  justify-content: center;
  justify-content: space-between;
}

.nav a {
  color: #262626;
}

.nav-btn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: none;
  font-family: inherit;
  font-size: 0.75em;
  cursor: pointer;
  background-color: transparent;
  min-height: 70px;
  color: inherit;
}

.nav-btn span {
  display: inline-block;
  margin-top: 0.7em;
}

.active button span {
  text-decoration: underline;
}

@media screen and (min-width: 275px) {
  .container {
    max-width: 250px;
    margin: auto;
    margin-bottom: 3em;
  }
}

@media screen and (min-width: 350px) {
  .container {
    max-width: 350px;
    margin-top: 240px;
  }
}

@media screen and (min-width: 540px) {
  .container {
    margin-top: 0;
  }
}
