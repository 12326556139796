.item-list {
  display: none;
  color: #262626;
}

.item {
  display: flex;
  flex-direction: column;
  font-size: 0.9rem;
  margin-bottom: 1.5em;
}
.item span {
  margin-top: 10px;
}
.section {
  margin-top: 3em;
  margin-bottom: 3.5em;
}

@media screen and (min-width: 320px) {
  .item {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 645px) {
  .item-list {
    display: block;
  }
}

@media screen and (min-width: 780px) {
  .item {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 845px) {
  .item {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 980px) {
  .item {
    font-size: 0.9rem;
  }
}
