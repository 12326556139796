.book-container {
  display: flex;
}

.bookcover-container {
  height: 100%;
  border: 4px solid #fff;
}

.bookcover-container img {
  height: 100%;
  width: 120px;
  object-fit: contain;
}

.book-info-container {
  text-align: left;
  padding: 0.5em 1.3em;
}

.book-title {
  margin: 0;
  font-size: 0.9rem;
}

.book-author {
  font-size: 0.8rem;
}

.return-info {
  width: 100%;
  margin-top: 4em;
}

.return-date span {
  margin-left: 0.5em;
}

.return-date svg {
  width: 10px;
  margin: 0;
}

.return-date span {
  font-size: 12px;
}

.numberOfDays {
  font-size: 12px;
  margin: 0.5em 0 1em;
  color: #33be77;
}

.warm {
  color: #ffb258;
}

.hot {
  color: #dc143c;
}

.actions small {
  font-size: 10px;
  display: block;
  margin-top: 0.5em;
}

.actions span {
  font-size: 12px;
}

.button-80 {
  background: transparent;
  backface-visibility: hidden;
  border-style: solid;
  border-width: 2px;
  border-color: #262626;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  margin-top: auto;
  padding: 0 2em;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 100;
  letter-spacing: -0.01em;
  line-height: 1.3;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(0.125rem);
}
.button-80:disabled {
  color: #aaa1ab;
  background-color: #ede0df;
  cursor: not-allowed;
}

.green {
  color: #0fb464;
}

.orange {
  color: #ffb157;
}

.red {
  color: #ff705c;
}

@media screen and (min-width: 320px) {
  .book-container {
    border-top: 1px solid #dbdbdb;
    border-bottom: 1px solid #dbdbdb;
  }
  .book-info-container {
    width: 50%;
  }
}

@media screen and (min-width: 725px) {
  .book-container {
    width: 95%;
    border: 1px solid #dbdbdb;
    margin: 1em auto;
  }
}
