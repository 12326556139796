.modal {
  position: fixed;
  border: 1px solid #262626;
  top: 50%;
  left: 50%;
  z-index: 250;
  overflow: hidden;
  background-color: #f7f8fa;
  transition: transform 90ms ease-out;
}

.modalClosed {
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0);
}

.modalOpen {
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.2s ease-out;
}

.backdropClosed {
  visibility: hidden;
  opacity: 0;
}

.backdropOpen {
  visibility: visible;
  opacity: 1;
}

.inner h1 {
  font-size: 15px;
  font-weight: 100;
  text-align: center;
}

.close-modal {
  position: absolute;
  right: 0;
  top: 0;
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.container {
  padding: 0 50px 50px 50px;
}
.control {
  margin-bottom: 1em;
}

.control input {
  width: 100%;
  border: none;
  font-family: inherit;
  font-size: 0.8rem;
  background-color: inherit;
  padding: 0.7em 0;
  margin-bottom: 0.4em;
  border-bottom: 1px solid #b3b3b3;
  outline: none;
}

.control input::placeholder {
  color: #b3b3b3;
}

.control input:focus {
  border-bottom: 1px solid #262626;
}

.control label {
  width: 100%;
  display: block;
  background-color: #262626;
  color: #f7f8fa;
  font-size: 0.9rem;
  margin: auto;
  padding: 2px;
  margin-bottom: 1.5em;
  text-align: center;
}

.submit-btn {
  width: 100%;
  display: block;
  border: none;
  border: 1px solid #262626;
  font-family: inherit;
  background-color: transparent;
  padding: 0.8em 0;
  cursor: pointer;
  color: #262626;
}

.btn {
  border: none;
  font-family: inherit;
  cursor: pointer;
  font-size: 0.8rem;
  color: blue;
  background-color: transparent;
  padding: 0;
  margin-bottom: 10px;
}

.note span {
  display: inline-block;
  margin-right: 6px;
}
.container p {
  font-size: 0.8rem;
}

.error {
  color: crimson;
}

.alert {
  font-size: 0.9rem;
  text-align: center;
}

.providers-error {
  text-align: center;
  font-size: 0.9rem;
  color: crimson;
}

.resetPasswordNote {
  padding: 5px;
  border: 1px solid #1f873d;
  color: #1f873d;
  background-color: #f8fff9;
}

/* Apply hover effect only on devices that support it */
@media (hover: hover) {
  .submit-btn:hover {
    background-color: #262626;
    color: #f7f8fa;
  }
}

.submit-btn:active {
  transform: scale(0.987);
}

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #262626;
  opacity: 1;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #262626;
  opacity: 1;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #262626;
  opacity: 1;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #262626;
  opacity: 1;
}

.btn-signin-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2em;
}

@media screen and (min-width: 280px) {
  .modal {
    max-width: 400px;
    width: 85%;
    padding-top: 2em;
  }
  .control input {
    padding: 0;
  }
  .control label,
  .control p,
  .btn,
  .note span {
    font-size: 0.6rem;
  }

  .control input::placeholder {
    font-size: 0.6rem;
  }
  .submit-btn {
    padding: 0.4em 0;
  }
}

@media screen and (min-width: 620px) {
  .modal {
    max-width: 400px;
    width: 85%;
    padding-top: 2em;
  }
  .control input {
    padding: 0.7em 0;
  }
  .control label,
  .control p,
  .btn,
  .note span {
    font-size: 0.9rem;
  }
  .control input::placeholder {
    font-size: 0.8rem;
  }
  .submit-btn {
    padding: 0.8em 0;
  }
}

@media screen and (min-width: 1080px) {
  .modal {
    max-width: 550px;
    width: 85%;
    padding: 2em;
  }
}
