/* .record-list {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.5em;
} */

@media screen and (min-width: 320px) {
  .record-list {
    display: grid;
    grid-template-columns: 270px;
    gap: 0.5em;
    justify-content: center;
  }
}

@media screen and (min-width: 460px) {
  .record-list {
    display: grid;
    grid-template-columns: 450px;
    gap: 0.5em;
    justify-content: center;
  }
}

@media screen and (min-width: 730px) {
  .record-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

/* @media screen and (min-width: 1100px) {
  .record-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
} */
