.job-applications {
  font-size: 0.9rem;
  border-collapse: collapse;
  width: 100%;
  margin-top: 1em;
}

.job-applications td,
.job-applications th {
  border: 1px solid #ddd;
  padding: 8px;
}

.job-applications tr:nth-child(even) {
  background-color: #f2f2f2;
}

.job-applications tr:hover {
  background-color: #ddd;
}

.job-applications th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  background-color: #1967d2;
  color: white;
}

@media screen and (max-width: 1260px) {
  .job-applications {
    font-size: 0.7rem;
  }
}

@media screen and (max-width: 1085px) {
  .job-applications {
    font-size: 0.6rem;
  }
}

@media screen and (max-width: 800px) {
  .job-applications {
    font-size: 0.8rem;
  }
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
}
