.icon {
  width: 25px;
  fill: #262626;
  margin-top: -4px;
  margin-right: 5px;
}

.active {
  fill: #dc143c;
}

@media screen and (min-width: 250px) {
  .icon {
    width: 15px;
  }
}

@media screen and (min-width: 590px) {
  .icon {
    width: 20px;
  }
}
