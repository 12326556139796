.pagination-buttons-container {
  margin: 0 auto;
  display: flex;
  flex: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 320px) {
  .pagination-buttons-container {
    width: 210px;
  }
}

@media screen and (min-width: 730px) {
  .pagination-buttons-container {
    width: 270px;
    margin-top: 3em;
  }
}
