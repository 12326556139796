.btn-primary {
  background-color: #ff4742;
  color: #fff;
}

.btn-primary:disabled {
  color: #aaa1ab;
  background-color: #ede0df;
  cursor: not-allowed;
}
.btn-primary:not(:disabled):hover {
  background-color: #c92a25;
}

.btn-secondary {
  background-color: #c1c1c1;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #9a9696;
}
.noResult {
  text-align: center !important;
  margin-bottom: 2em !important;
}
@media screen and (min-width: 280px) {
  .modal-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em 0 0.5em 0;
  }
  .modal-actions button {
    border: none;
    padding: 0.4em 0.8em;
    margin: 0 0.3em 0 0.3em;
    cursor: pointer;
    font-size: 0.8rem;
    font-family: inherit;
  }

  .btn-primary {
    background-color: #ff4742;
    color: #fff;
  }

  .btn-secondary {
    background-color: #c1c1c1;
    color: #fff;
  }
}

@media screen and (min-width: 725px) {
  .modal-actions {
    display: flex;
    padding: 1.5em 0 0.2em 0;
  }
  .modal-actions button {
    border: none;
    padding: 0.8em 1.8em;
    margin: 0 0.3em 0 0.3em;
    cursor: pointer;
    font-size: 0.9rem;
    font-family: inherit;
  }
}
