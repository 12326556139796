.controls input {
  background-color: #e6e6e6;
  height: 36px;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
  font-family: inherit;
  font-size: 0.9rem;
}

.controls input::placeholder {
  color: #656565;
}

.controls input:focus {
  background-color: #fff;
  outline: 2px solid #1967d2;
}
.controls input:focus::placeholder {
  color: #9e9e9e;
}

.btn-primary {
  background-color: #ff4742;
  color: #fff;
}

.btn-primary:hover {
  background-color: #c92a25;
}

.btn-secondary {
  background-color: #c1c1c1;
  color: #fff;
}

.btn-secondary:hover {
  background-color: #9a9696;
}

@media screen and (min-width: 250px) {
  .controls {
    margin: 1em 0;
  }
  .controls input {
    width: 100%;
  }
  .controls label {
    text-align: left;
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.5em;
  }
}

@media screen and (min-width: 280px) {
  /* BUTTONS */
  .modal-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 1em 0 0.5em 0;
  }
  .modal-actions button {
    border: none;
    padding: 0.4em 0.8em;
    margin: 0 0.3em 0 0.3em;
    cursor: pointer;
    font-size: 0.8rem;
    font-family: inherit;
  }

  .btn-primary {
    background-color: #ff4742;
    color: #fff;
  }

  .btn-secondary {
    background-color: #c1c1c1;
    color: #fff;
  }
}

@media screen and (min-width: 725px) {
  .modal-actions {
    display: flex;
    padding: 1.5em 0 0.2em 0;
  }
  .modal-actions button {
    border: none;
    padding: 0.8em 1.8em;
    margin: 0 0.3em 0 0.3em;
    cursor: pointer;
    font-size: 0.9rem;
    font-family: inherit;
  }
}
