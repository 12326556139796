.svg {
  width: 50px;
}

@media screen and (min-width: 320px) {
  .svg {
    width: 40px;
  }
}

@media screen and (min-width: 540px) {
  .svg {
    width: 50px;
  }
}
