.main {
  position: absolute;
  left: 256px;
  right: 0;
  height: auto;
  text-align: center;
  color: #262626;
}

@media screen and (max-width: 1060px) {
  .main {
    position: static;
  }
}

.page-container {
  position: relative;
  margin: 0 auto;
  text-align: center;
}

.main-container {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 7em;
}

.menu-btn {
  display: none;
}

@media screen and (min-width: 851px) {
  .main-container {
    margin-top: 0;
  }
}

@media screen and (max-width: 950px) {
  .main {
    position: static;
  }
}

@media screen and (min-width: 1640px) {
  .main-container {
    margin-top: 7em;
  }
}
