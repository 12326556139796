.control {
  display: flex;
  justify-content: center;
  margin-top: 3em;
}
.input-container {
  flex: 1;
  max-width: 400px;
  position: relative;
}
.control input {
  position: relative;
  width: 100%;
  background-color: transparent;
  border: 2px solid #262626;
  font-family: inherit;
  padding: 0.8em 3em;
}
.control .select {
  background-color: transparent;
  border: 2px solid #262626;
  font-family: inherit;
}
.button-52 {
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 1px;
  outline: 0;
  border: 1px solid black;
  cursor: pointer;
  position: relative;
  background-color: rgba(0, 0, 0, 0);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-52:after {
  content: "";
  background-color: #f8b26a;
  width: 100%;
  z-index: -1;
  position: absolute;
  height: 100%;
  top: 5px;
  left: 5px;
  transition: 0.2s;
}

.button-52:active {
  transform: scale(0.987);
}
.button-52:hover:after {
  top: 0px;
  left: 0px;
}

.browse-btn {
  display: block;
  margin: 2em auto;
  border: none;
  padding: 0;
  background-color: transparent;
  font-family: inherit;
  font-size: 1rem;
  cursor: pointer;
  text-decoration: underline;
}

.button-80 {
  background: #fff;
  backface-visibility: hidden;
  border-radius: 0.375rem;
  border-style: solid;
  border-width: 0.125rem;
  box-sizing: border-box;
  color: #212121;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 100;
  letter-spacing: -0.01em;
  line-height: 1.3;
  margin-bottom: 1em;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  transition: transform 0.2s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(0.125rem);
}

@media screen and (min-width: 320px) {
  .control input {
    font-size: 0.59em;
    padding: 1.5em 3em;
  }
  .control .select {
    font-size: 0.59em;
  }
}

@media screen and (min-width: 420px) {
  .control input {
    font-size: 0.9rem;
    padding: 0.8em 3em;
  }
  .control .select {
    font-size: 0.9rem;
  }
}
