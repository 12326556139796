.borrowed-book {
  margin-top: 1em;
}
.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #262626;
  padding: 0.5em;
}
.label {
  display: block;
  text-align: left;
}

.book {
  display: flex;
  flex-direction: column;
}

.book-data {
  text-align: left;
}

.confirmBtn {
  border: none;
  background-color: transparent;
}

.confirmBtn:active {
  transform: scale(0.9);
}

@media screen and (min-width: 320px) {
  .book {
    font-size: 0.5rem;
    margin-bottom: 2em;
    align-items: left;
  }

  .item {
    flex-direction: column;
  }
}

@media screen and (min-width: 470px) {
  .book {
    font-size: inherit;
    margin-bottom: 0;
  }

  .item {
    flex-direction: row;
  }
}
