.card {
  border-radius: 5px;
  box-shadow: 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
  background-color: #ffffff;
  /* padding: 2em 2.5em 5em 2.5em; */
}

@media screen and (min-width: 320px) {
  .card {
    padding: 1em;
  }
}

@media screen and (min-width: 450px) {
  .card {
    padding: 2em 2.5em 5em 2.5em;
  }
}
