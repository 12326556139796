.title {
  text-transform: uppercase;
}

.noResultNote {
  margin: auto;
}

@media screen and (min-width: 275px) {
  .events-wrapper {
    margin-top: 2em;
  }
  .events-wrapper .title {
    font-size: 1.3rem;
    margin-bottom: 0.9em;
  }
}

@media screen and (min-width: 320px) {
  .events-container {
    display: grid;
    grid-template-columns: 310px;
    justify-content: center;
  }
}

@media screen and (min-width: 540px) {
  .events-wrapper .title {
    font-size: 1.6rem;
    margin-top: 1.5em;
    margin-bottom: 1.8em;
  }
}

@media screen and (min-width: 680px) {
  .events-container {
    display: grid;
    grid-template-columns: repeat(2, 310px);
  }
}

@media screen and (min-width: 850px) {
  .events-container {
    grid-template-columns: repeat(3, 1fr);
    justify-content: start;
  }
}
