.image {
  width: 100%;
  height: unset;
  grid-column: span 2;
}

.image:nth-of-type(4) {
  grid-column: 1 / span 3;
}

.image:nth-of-type(5) {
  grid-column: 4 / span 3;
}
.image-container .image img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  -o-object-position: center center;
  object-fit: cover;
  object-position: center center;
}

@media screen and (min-width: 320px) {
  .image-container {
    display: none;
  }
}

@media screen and (min-width: 768px) {
  .image-container {
    width: calc(100% - 90px);
    max-width: 1075px;
    height: unset;
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(3, 1fr);
    padding: 0 45px;
    margin: auto;
    margin-bottom: 10em;
    margin-top: 2.5em;
  }
}

@media screen and (min-width: 1024px) {
  .image-container {
    grid-template-rows: repeat(3, 400px);
  }
}
