.modal {
  position: fixed;
  border: 1px solid #262626;
  top: 50%;
  left: 50%;
  z-index: 200;
  overflow: scroll;
  background-color: #f7f8fa;
  transition: transform 90ms ease-out;
}

.modalClosed {
  visibility: hidden;
  transform: translate(-50%, -50%) scale(0);
}

.modalOpen {
  visibility: visible;
  transform: translate(-50%, -50%) scale(1);
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 100;
  background: rgba(0, 0, 0, 0.75);
  transition: opacity 0.2s ease-out;
}

.backdropClosed {
  visibility: hidden;
  opacity: 0;
}

.backdropOpen {
  visibility: visible;
  opacity: 1;
}

.close-modal {
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 15px;
  margin-right: 15px;
  cursor: pointer;
}

@media screen and (min-width: 280px) {
  .modal {
    width: 250px;
    height: auto;
    padding: 2.5em;
  }

  .modal-header {
    border-bottom: 1px solid #717e86;
    text-align: center;
  }
  .title {
    color: #262626;
    font-size: 1rem;
  }

  .modal-body {
    font-size: 0.7rem;
    padding: 1em 0;
    border-bottom: 1px solid #717e86;
    text-align: center;
  }
  .modal-body p {
    margin: 0;
  }

  .close-modal {
    top: -6px;
    right: -3px;
  }
  .close-modal svg {
    width: 17px;
  }
}

@media screen and (min-width: 470px) {
  .modal {
    width: 400px;
    height: auto;
    padding: 2.5em;
  }
}

@media screen and (min-width: 520px) {
  .modal {
    width: 450px;
    height: auto;
    padding: 2em;
  }
  .close-modal {
    top: -6px;
    right: -3px;
  }
  .close-modal svg {
    width: 17px;
  }
}

@media screen and (min-width: 725px) {
  .modal {
    width: 600px;
    height: auto;
    padding: 2.5em;
  }

  .modal-header {
    border-bottom: 1px solid #717e86;
  }
  .modal-header h2 {
    text-align: left;
    font-size: 1.2rem;
    color: #262626;
  }

  .modal-body {
    padding: 2em 0;
    border-bottom: 1px solid #717e86;
  }

  .modal-body p {
    text-align: left;
    font-size: 0.9rem;
  }

  .close-modal {
    top: -2px;
    right: 3px;
  }
  .close-modal svg {
    width: 17px;
  }
}
