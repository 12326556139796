@media screen and (min-width: 320px) {
  .book-list {
    display: grid;
    grid-template-columns: 270px;
    gap: 0.5em;
    justify-content: center;
  }

  .pagination-buttons-container {
    width: 220px;
  }
}

@media screen and (min-width: 730px) {
  .book-list {
    display: grid;
    grid-template-columns: repeat(2, 270px);
  }

  .pagination-buttons-container {
    width: 280px;
  }
}

@media screen and (min-width: 1100px) {
  .book-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
}
