.wishlist-item {
  display: flex;
  text-align: left;
  border: 1px solid #dbdbdb;
  margin-bottom: 0.5em;
}

.wishlist-item h3 {
  margin: 0;
}

.wishlist-item img {
  width: 70px;
  height: 110px;
  object-fit: cover;
}

.book-data {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 1em;
  padding: 0.5em;
}

.button-80 {
  background: transparent;
  backface-visibility: hidden;
  border-style: solid;
  border-width: 2px;
  border-color: #262626;
  color: #262626;
  cursor: pointer;
  display: inline-block;
  margin: 0 0.3em;
  padding: 0 2em;
  font-family: inherit;
  font-size: 0.8rem;
  font-weight: 100;
  letter-spacing: -0.01em;
  line-height: 1.3;
  position: relative;
  text-align: left;
  text-decoration: none;
  transform: translateZ(0) scale(1);
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-80:not(:disabled):hover {
  transform: scale(1.05);
}

.button-80:not(:disabled):hover:active {
  transform: scale(1.05) translateY(0.125rem);
}

.button-80:focus {
  outline: 0 solid transparent;
}

.button-80:focus:before {
  content: "";
  left: calc(-1 * 0.375rem);
  pointer-events: none;
  position: absolute;
  top: calc(-1 * 0.375rem);
  transition: border-radius;
  user-select: none;
}

.button-80:focus:not(:focus-visible) {
  outline: 0 solid transparent;
}

.button-80:focus:not(:focus-visible):before {
  border-width: 0;
}

.button-80:not(:disabled):active {
  transform: translateY(0.125rem);
}

.button-80:disabled {
  color: #aaa1ab;
  background-color: #ede0df;
  cursor: not-allowed;
}

.book-status {
  width: 120px;
  display: block;
  font-size: 0.8rem;
  padding: 2px 3px;
  margin-top: auto;
  margin-bottom: 0.5em;
  text-align: center;
}
.green {
  color: white;
  background-color: #019267;
}

.red {
  color: white;
  background-color: #e83a14;
}
@media screen and (min-width: 320px) {
  .title {
    font-size: 0.9rem;
  }
  .author {
    font-size: 0.7rem;
  }

  .button-80 {
    font-size: 0.8rem;
  }

  .wishlist-item {
    border-left: none;
    border-right: none;
  }
}

@media screen and (min-width: 725px) {
  .wishlist-item {
    width: 95%;
    margin: 0.5em auto;
    border: 1px solid #dbdbdb;
  }
  .title {
    font-size: 1rem;
  }
  .author {
    font-size: 0.8rem;
  }
  .button-80 {
    font-size: 0.8rem;
  }
}
