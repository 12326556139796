.eventsNav {
  margin-top: 4em;
}

.btn {
  display: flex;
  align-items: center;
  border: 0;
  background: none;
  color: #262626;
  cursor: pointer;
  font-size: 0.97rem;
  margin: 13px 0 13px 0;
  padding: 2px;
  text-transform: uppercase;
}

.active {
  color: #dc143c;
}
