.tabs {
  margin-bottom: 1em;
}

.tab-btn-container {
  position: relative;
  display: flex;
  justify-content: space-between;
}
.tab-btn-container a {
  text-decoration: none;
}

.tab-btn {
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: calc(100% / 2);
  font-family: inherit;
  font-size: 0.9rem;
  font-weight: 800;
  color: #476282;
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-transform: uppercase;
  margin-bottom: 0.5em;
}

.tab-indicator {
  position: relative;
  width: calc(100% / 2);
  height: 5px;
  background-color: #1967d2;
  left: 0;
  border-radius: 5px;
  transition: all 200ms ease-in-out;
}

.active {
  color: #1967d2;
}

@media screen and (min-width: 250px) {
  .tab-header {
    max-width: 250px;
    margin: auto;
  }

  .tab-btn {
    font-size: 0.55rem;
    margin-bottom: 0.3em;
  }

  .tab-indicator {
    height: 3px;
  }

  .title {
    font-size: 1.3rem;
  }
}

@media screen and (min-width: 420px) {
  .tab-header {
    max-width: 320px;
    margin: auto;
  }

  .tab-btn {
    font-size: 0.8rem;
    margin-bottom: 0.3em;
  }

  .tab-indicator {
    height: 3px;
  }

  .title {
    font-size: 1.6rem;
  }
}
