.slide {
  max-width: 200px;
  margin: 0 auto;
  margin-top: 2em;
  cursor: grab;
}

.img {
  border: 4px solid #262626;
}

.info {
  margin-top: 0.5em;
}
.info span {
  font-size: 0.7rem;
  margin: 0.2em 0;
}

.title {
  margin: 0;
}

.artist {
  text-align: center !important;
  margin: 0;
  margin-bottom: 2em;
  font-size: 0.8rem !important;
}
