.mobile-nav {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 60px;
  font-size: 0.6rem;
  background-color: #051e34;
  color: #dde0e3;
  z-index: 10;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: inherit;
  height: 100%;
  width: 100%;
}

.active {
  color: #669df6;
  background-color: rgba(71, 98, 130, 0.4);
}

.active label {
  text-decoration: underline;
}

.link span {
  margin-top: 5px;
}

.link:hover {
  background-color: rgba(71, 98, 130, 0.4);
}

.mobile-nav a {
  position: relative;
}

.indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 50px;
  position: absolute;
  left: 55%;
  bottom: 60%;
  font-size: 0.7rem;
  background-color: crimson;
  color: #fff;
}

.back-btn {
  position: absolute;
  top: -50px;
  left: 10px;
  cursor: pointer;
  border: none;
  background-color: #051e34;
  color: #fff;
  padding: 1em;
  border-radius: 100px;
  opacity: 0.8;
  font-family: inherit;
}

@media screen and (min-width: 250px) {
  .link {
    font-size: 0.6rem;
  }
}

@media screen and (min-width: 590px) {
  .link {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 1061px) {
  .mobile-nav {
    display: none;
  }
}
