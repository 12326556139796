.header {
  width: 100%;
  height: 90px;
  position: fixed;
  top: 0;
  z-index: 14;
  background-color: #f7f8fa;
  transition: transform 0.5s ease 0ms, height 0.5s ease 0ms;
}

/* CLASS ADDED ON useEffect when window.pageYOffset > 25 */

.sticky-header {
  transition: 0.3s ease-out;
  box-shadow: 0 1px 4px #80808040;
  opacity: 1;
}

/* NAVIGATION PANEL */

.nav-panel {
  position: absolute;
  width: 100%;
  height: 90px;
  left: 0;
  right: 0;
  transition: all 0.25s ease;
  background-color: #f7f8fa;
}

.nav-open {
  height: 100vh;
}

/* CLOSE BUTTON ON NAV PANEL */
.close-btn {
  position: absolute;
  left: 50%;
  top: 44px;
  width: 15px;
  height: 15px;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  transform: translateX(-50%);
}

.close-btn span {
  display: block;
  width: 100%;
  height: 1px;
  left: 0;
  top: 7px;
  background-color: #000;
  position: absolute;
}

.close-btn span.one {
  transform: rotate(-45deg);
}
.close-btn span.two {
  transform: rotate(45deg);
}

.nav-open .close-btn {
  opacity: 1;
  visibility: visible;
  transition: all 0.75s ease 0.25s;
  z-index: 10;
}

.header .login-btn {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  border: none;
  background: none;
  cursor: pointer;
  opacity: 1;
  visibility: visible;
  transition: all 0.25s ease;
  font-size: 0.889rem;
  padding: 0;
  margin: 0;
}

.nav-open .login-btn {
  opacity: 0;
  visibility: hidden;
}

.logo-link {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  bottom: 8px;
  width: 90px;
  z-index: 10;
}

header .nav {
  position: absolute;
  width: 300px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  transition: all 50ms ease;
  z-index: 10;
}

.nav-open nav {
  opacity: 1;
  visibility: visible;
  transition: all 0.75s ease 0.25s;
}

ul {
  padding: 0;
  list-style-type: none;
}

.nav ul li {
  margin-bottom: 1em;
}

.nav ul li a {
  color: inherit;
  text-decoration: none;
  letter-spacing: 2px;
}

.nav a:hover {
  text-decoration: underline;
}

.nav .active {
  color: #e15b64;
}

.test {
  width: 300px;
  margin-left: auto;
}

@media screen and (min-width: 275px) {
  .header .login-btn {
    right: 25px;
  }

  .nav a {
    font-size: 12px;
  }
}

@media screen and (min-width: 540px) {
  .nav a {
    font-size: 16px;
  }
}
@media screen and (min-width: 1280px) {
  .header .login-btn {
    right: 100px;
  }
}
@media screen and (min-width: 1920px) {
  .header .login-btn {
    right: 190px;
  }
}
