.footer {
  margin: 0 auto;
  max-width: 600px;
  margin-bottom: 2em;
  text-align: center;
  padding: 0 1em;
}

.footer h1 {
  line-height: 0.8;
  position: relative;
  font-size: 1.4rem;
  margin: 0;
  margin-bottom: 1em;
}

.footer .logo {
  display: block;
  top: -5px;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 70px;
}

.footer-credits {
  border-top: 1px solid #78848d;
  font-size: 0.9rem;
  max-width: 100%;
}

.footer-credits button {
  color: inherit;
  font-family: inherit;
  font-size: 12px;
  border: none;
  background-color: transparent;
  border-bottom: 1px dashed #a2a2a2;
  transition: border 0.15s ease 0ms;
  cursor: pointer;
}

.footer-credits button:hover {
  border-bottom: 1px solid #a2a2a2;
}

.footer-credits small {
  display: block;
  margin-top: 1.3em;
  line-height: 1.5;
}

.test {
  font-size: 0.8rem;
}
