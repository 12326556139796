.checkbox-group small {
  display: block;
  margin-bottom: 1em;
}

.genres-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.genres-grid label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.6em;
}

.genres-grid span {
  margin-right: 0.5em;
}

@media screen and (min-width: 250px) {
  .checkbox-group {
    margin-bottom: 0.8rem;
  }

  .checkbox-group legend {
    font-size: 0.9rem;
  }

  .genres-grid {
    grid-template-columns: repeat(1, 1fr);
  }

  .genres-grid span {
    font-size: 0.8rem;
  }
}

@media screen and (min-width: 450px) {
  .genres-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
