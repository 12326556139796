.svg {
  width: 15px;
  cursor: pointer;
  fill: #008000;
}

@media screen and (max-width: 1260px) {
  .svg {
    width: 12px;
  }
}
