.form {
  max-width: 600px;
  margin: 0 auto;
  /* border: 1px solid #262626; */
  background-color: #f7f8fa;
  padding: 2.5em;
  flex: 1;
}

.controls textarea {
  width: 100%;
  min-height: 200px;
  resize: none;
  padding: 10px;
  background-color: #e6e6e6;
  border-radius: 5px;
  border: none;
  font-size: 0.9rem;
  font-family: inherit;
}
.controls textarea::placeholder {
  color: #656565;
}

.controls input {
  background-color: #e6e6e6;
  height: 36px;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
  font-family: inherit;
  font-size: 0.9rem;
}

.file-control {
  background-color: #e6e6e6;
  height: 36px;
  border: none;
  padding: 0 10px;
  border-radius: 5px;
  font-family: inherit;
  font-size: 0.9rem;
  text-align: left;
}

.controls input::placeholder {
  color: #656565;
}

.controls input:focus,
.controls textarea:focus {
  background-color: #fff;
  outline: 2px solid #1967d2;
}
.controls input:focus::placeholder {
  color: #9e9e9e;
}

.action {
  margin-top: 3em;
}

/* Reservation button */
.button-24 {
  background: #ff4742;
  border: 1px solid #ff4742;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 2px 4px;
  box-sizing: border-box;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  font-family: nunito, roboto, proxima-nova, "proxima nova", sans-serif;
  font-size: 16px;
  font-weight: 800;
  line-height: 16px;
  min-height: 40px;
  outline: 0;
  padding: 12px 14px;
  text-align: center;
  text-rendering: geometricprecision;
  text-transform: none;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  vertical-align: middle;
}

.button-24:hover,
.button-24:active {
  background-color: initial;
  background-position: 0 0;
  color: #ff4742;
}

.button-24:active {
  opacity: 0.5;
}

@media screen and (min-width: 250px) {
  .controls {
    margin: 1em 0;
  }
  .controls input {
    width: 100%;
  }
  .controls label {
    text-align: left;
    display: block;
    font-size: 0.8rem;
    margin-bottom: 0.5em;
  }
}
