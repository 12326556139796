.opening-hours-outter {
  margin-top: 3.5em;
}

.opening-hours-inner {
  /* display: grid;
  grid-template-columns: repeat(3, 1fr); */
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.item {
  flex: 0 0 calc(35% - 20px);
}

.bold {
  background-color: #000;
  color: #f7f8fa;
}

@media screen and (min-width: 275px) {
  .opening-hours-inner p {
    font-size: 0.7rem;
  }

  .bold {
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 500px) {
  .opening-hours-inner p {
    font-size: 1rem;
  }
  .bold {
    font-size: 1.1rem;
  }
}
