.collage-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  margin-top: 3em;
  margin-bottom: 2em;
}

.vinyls-collage-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2em;
  margin-top: 3em;
  margin-bottom: 2em;
}

.table-image {
  margin-top: 2em;
}

@media screen and (min-width: 320px) {
  .collage-container {
    display: grid;
    grid-template-columns: 100%;
    gap: 1em;
    margin-top: 3em;
    margin-bottom: 2em;
  }
}

@media screen and (min-width: 675px) {
  .collage-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1em;
    margin-top: 3em;
    margin-bottom: 2em;
  }
}
